import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import videojs from 'video.js';
import "video.js/dist/video-js.css";
import "videojs-mux";
import AfrowatchVideoPlayer from "./AfrowatchVideoJS";
import MovieSliderSimilarMovies from "../Slider/MovieSliderSimilarMovies";
import Footer from "../Footer/Footer";
import { axiosInstance } from "../../apiClient";
import sampleStreamApiResponse from "../../examples/streamResponse.json";
import {resolveSourceTypeFromVideoType} from "./controls/_utils";
import TopNavBar from "../Nav/TopNavBarV2";
import BottomNav from "../Nav/BottomNav";
import "../MoviesPage/MoviesPage.css";
import "./MediaPlayer.css";

const MediaPlayerPage = (props) => {
	const location = useLocation();
	const [mediaStream, setMediaStream] = useState({});
	const [featuredStream, setFeaturedStream] = useState();
	const [isMoviesComp, setComp] = useState(false);
	const [isNavNotShowing, showNav] = useState(true);
	const [pageloading, setPageLoading] = useState(false);
	const playerRef = useRef(null);
	const videoRef = useRef(null)

	const userId = localStorage.getItem("currentUser"); //
	const profileId = localStorage.getItem("selected_prof_id");
	const profileName = localStorage.getItem("profile_name");
	const region = localStorage.getItem("region");
	const contentId = location.state?.contentId || "no-id";
	const actors = location.state?.actors || [];

	// When its the Default Page (Movies page)
	const regex = /^\/profile\/[a-f0-9]{24}\/watch\/?$/;
	let featurePage = regex.test(window.location.pathname)

	function isMovieComponent() {
		const check = location.pathname.split("/");
		return !!(check.indexOf("watch") && check.indexOf("profile") !== -1);
	}

	async function getContentStreamInfo(userId, contentId) {
		setPageLoading(true);
		try {
			const resp = await axiosInstance.get(`${userId}/content/${contentId}/stream/`);

			if (resp.data.status === 200 && resp.data.content) {
				setPageLoading(false);
				console.log(resp.data.content, 'resp content data');
				return resp.data.content; //API reference: /examples/streamResponse.json
			}
		} catch (e) {
			if (window.location.host === "http://localhost:3000") {
				setPageLoading(false);
				console.log(`Error occured ${e}, Using default data for test environment`);
				return sampleStreamApiResponse.data.content;
			} else {
				return null;
			}
		}
	}

	const getDisplayContent = async () => {
		let contentIds = [];
		const contentReq = `/content/${localStorage.getItem("region")}/trending`;

		function getUrl(contentId) {
			return `${localStorage.getItem("currentUser")}/content/${contentId}/stream`;
		}

		const res = await axiosInstance.get(contentReq);
		if (res.data.status === 200) {
			for (const content of res.data.content) {
				contentIds.push(content["content_id"]);
			}

			const setRandomUrl = () => {
				const randomIndex = Math.floor(Math.random() * contentIds.length);
				const randomContentId = contentIds[randomIndex];
				const url = getUrl(randomContentId);
				console.log(`Setting URL to: ${url}`);
				return url;
			}

			// set initial URL etc..
			setRandomUrl();

			const URL = setRandomUrl();
			// Make initial req to stream, probably need to cache the responses to save cost
			const resTwo = await axiosInstance.get(URL);
			if (resTwo.data.status === 200) {
				console.log(resTwo.data.content.stream["hls_stream_path"]);
				setFeaturedStream(resTwo.data.content.stream);
			}
			localStorage.setItem("featured_stream_id", URL.split("/")[2]);

			// Every 3 minutes swap display (featured) content * 60000
			setInterval(async () => {
				setRandomUrl();
				localStorage.setItem("featured_stream_id", URL.split("/")[2]);

				const resTwo = await axiosInstance.get(URL);
				if (resTwo.data.status === 200) {
					setFeaturedStream(resTwo.data.content.stream);
				}
			}, 180000);
		}
	};

	useEffect(() => {
		getDisplayContent()
	},[featurePage])

	useEffect(() => {
		setComp(isMovieComponent());

		//For video metadata
		getContentById()
	}, []);

	const getContentById = async () =>{
		let res = await axiosInstance.get(`/content/${contentId}`)
		const {data} = res
		if(res.data.status === 200){
			videoRef.current = data.data
			console.log(videoRef.current)
		}
	}

	useEffect(() => {
		let contentStream
		const getStream = async ()=>{
			if(!featurePage){
				contentStream = await getContentStreamInfo(userId, contentId)
			}else{
				contentStream = featuredStream
				console.log('FEATURED\n', contentStream)
			}

			if(contentStream){
				setMediaStream(contentStream) //The featuredStream is not being set here in time when its the default movies page
			}
		}
		getStream()
	}, [contentId])

	const [isFooter, setFooter] = useState({
		footerActive: true,
		bottomNavActive: false,
	});

	useEffect(()=>{
		if ($(window).width() <= 600) {
			setFooter({
				...isFooter,
				footerActive: false,
				bottomNavActive: true,
			});
		} else {
			setFooter({
				...isFooter,
				footerActive: true,
				bottomNavActive: false,
			});
		}
	},[])

	// useEffect(() => {
	// 	if (mediaStream) {
	// 		console.log(mediaStream.stream);
	// 	}
	// }, [mediaStream]);
	
	let showNavigation = () => showNav(!isNavNotShowing);

	const videoJsOptions = {
		autoplay: false,
		controls: true,
		responsive: true,
		fluid: true,
		enableSmoothSeeking: true,
		nativeTextTracks: true,
		preloadTextTracks: true,
		sources: mediaStream && mediaStream?.stream ?  [{
			src: mediaStream && mediaStream?.stream['hls_stream_path'] ? `https://assets.afrowatch.co/${mediaStream?.stream['hls_stream_path']}` : "http://qthttp.apple.com.edgesuite.net/1010qwoeiuryfg/sl.m3u8", //https://v2.kickstarter.com/1713027909-jW3I9hwcFUyHKkQ235iQTtuOr8WCiEDfUKHh98orp9E%3D/projects/4741898/video-1277660-hls_playlist.m3u8
			type: mediaStream && mediaStream?.stream['video_content_type'] ? resolveSourceTypeFromVideoType(mediaStream?.stream['video_content_type']) : 'application/x-mpegURL'
		}] : [{
			src: "https://d2fft6uz2yutyc.cloudfront.net/Launch-vid-cut.m4v",
			type: 'video/mp4' 
		}],
		controlBar: {
			skipButtons : {
				forward: 10,
				backward: 10
			},
		},
		muted: featurePage ? true : false,
		plugins: {
			mux: {
				debug: false,
				disableCookies: true,
				data: {
					env_key: process.env.REACT_APP_MUX_TOKEN || 'k127micvjrhtfa6kbuch63ju8', // required
					// Metadata
					player_name: "Afrowatch Player",
					viewer_user_id: userId, //currently userid not profileId
					viewer_profile_id: profileId,
					viewer_profile_name: profileName, 
					// Player Metadata
					player_version: '1.0.0', 
					// Video Metadata
					video_id: mediaStream && mediaStream?.stream?.content_id,
					video_stream_type: 'on-demand',
					video_cdn: 'cloud-front',
					video_title: videoRef && videoRef.current?.content_name,
					video_content_type: videoRef && videoRef.current?.content_type,
					//subtitles in our case
					video_language_code: videoRef && videoRef.current?.subtitles.toString(),
					//animators
					video_producer:videoRef && videoRef.current?.animators?.toString(),
					//we store our length in (s)
					video_duration:videoRef && videoRef.current?.length,
					// You can only have 1-10 custom metadata and they have to be Strings
					//Custom 1: Stream id
					custom_1:mediaStream && mediaStream?.stream?.stream_id,
					//Custom 2 : creator id
					//Check if stream content id is in vendor lisr array, if it is set the creator id
					custom_2: videoRef && videoRef.current?.creator_id
					//Custom 3: video Genre
					//custom_3: videoRef && videoRef.current?.genre
					//Custom 4: Mux playback id, to get playback from mux in portal
				}
			}
		}
	};
	// Language 
	const defaultLanguage = { code: 'en',label: 'English' };

	let tracks = []
	try {
		tracks = mediaStream && mediaStream.stream['subtitles_list'] ? mediaStream.stream['subtitles_list'] : [
			{
				src: 'https://res.cloudinary.com/dz5b76kzm/raw/upload/v1711535218/subtitles/en_htuk8r.vtt',
				kind: 'subtitles',
				srclang: 'en', // Source language
				label: 'English',
				//default: true,
			},
			{
				src: 'https://res.cloudinary.com/dz5b76kzm/raw/upload/v1711535187/subtitles/yo_lyl7dp.vtt',
				kind: 'subtitles',
				srclang: 'yb', // Source language
				label: 'Yoruba',
			},
		]
	} catch (error) {
		console.log(error)
	}
  
	const handlePlayerReady = (player) => {
		playerRef.current = player;
		// You can handle player events here, for example:
		player.on('waiting', () => {
			videojs.log('player is waiting');
		});
	
		player.on('dispose', () => {
			videojs.log('player will dispose');
		});
	};

	return (
		<div className="video-player-page">
			{isMoviesComp ? null : <TopNavBar region={region} profile={profileName} navToggle={showNavigation} />}

			{
				pageloading ?  
					<div className="no-video">
						<LinearProgress disableShrink className="d-block m-auto" color="secondary" fourColor style={{}}/>
						<div data-vjs-player>
							<div  className="video-js"/>
						</div>
					</div>
					:
					<AfrowatchVideoPlayer streamData={mediaStream}  options={videoJsOptions} onReady={handlePlayerReady} tracks={tracks} language={defaultLanguage} navDrawer={props.navDrawer}/>
			}
			
			{isMoviesComp ? null : (
				<div>
					<div className="container-fluid">
						<div className="row mt-5">
							<div className="col-md-12">
								<h1 className="row-header-title mb-md-4 mt-md-2 ml-md-3">
                                    Similar Animation
								</h1>
							</div>
						</div>

						{actors.length > 0 ? (
							<MovieSliderSimilarMovies actors={actors} />
						) : (
							<div className="container">
								<div className="row">
									<div className="col-md-12">
										<div className="text-center">
                                            No similar movies found
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			)}

			{isMoviesComp ? null :( isFooter.footerActive ? <Footer /> : (<BottomNav/>))}
		</div>
	);
};

export default MediaPlayerPage;