import { useState, useEffect } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import './element.css';

const Pay = () => {
	const stripe = useStripe();
	const elements = useElements();
	const navigate = useNavigate();

	const [paySuccess, setSuccess] = useState(false);
	const [payError, setPayError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isCardComplete, setIsCardComplete] = useState(false);
	const [alertMessage, setMessage] = useState("");



	const pay = async (event) => {
		event.preventDefault();
		if (!stripe || !elements) {
			return;
		}
		setLoading(true);

		const getReturnUrl = () => {
			switch (process.env.REACT_APP_BASE_URL) {
			case "http://localhost:3001":
				return `http://localhost:3000/whos-watching`;
			case "https://dev-api.afrowatch.co":
				return "https://beta.afrowatch.co/whos-watching";
			case "https://api.afrowatch.co":
				return "https://afrowatch.co/whos-watching";
			default:
				return "http://localhost:3000/whos-watching";
			}
		};

		const result = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: getReturnUrl()
			},
		});

		if (result.error) {
			setLoading(false);
			setSuccess(false);
			setPayError(true);
			setMessage(result.error.message);
		} else {
			setLoading(false);
			setSuccess(true);
			setMessage('Successfully paid for subscription');
		}

		ReactGA.event({
			category: 'Plans & Subscription',
			action: 'Payment button clicked in stripe U.I'
		});
	};

	useEffect(() => {
		if (!elements) {
			return;
		}

		const paymentElement = elements.getElement(PaymentElement);

		const handleChange = (event) => {
			setIsCardComplete(event.complete);
		};

		if (paymentElement) {
			paymentElement.on('change', handleChange);
		}

		return () => {
			if (paymentElement) {
				paymentElement.off('change', handleChange);
			}
		};
	}, [elements]);

	

	return (
		<form id='payment-form' onSubmit={pay}>
			<PaymentElement />
            
			<button id='pay-submitBtn' disabled={!isCardComplete || !stripe} className='subscribe-btn py-2 mt-2'>
				{!loading ? 'Submit' : <CircularProgress style={{ width: '20px', height: '20px' }} color="inherit" />}
			</button>

			<Snackbar open={paySuccess || payError} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
				<Alert style={{ marginBottom: '1rem' }} icon={<CheckCircleOutlineIcon fontSize="inherit" />} sx={{ width: '100%' }} severity={paySuccess ? "success" : "error"}>
					{alertMessage}
				</Alert>
			</Snackbar>
		</form>
	);
};

export default Pay;
