import React, {useContext, useState, useEffect} from 'react';
import { ModalContext } from '../../context/ModalContext';
import NavBarSearch from '../Nav/TopNavBarV2'
import Footer from '../Footer/Footer';
import {Link} from 'react-router-dom';
import {Tabs, Tab} from 'react-bootstrap';
import CurrentPlan from '../Profile/CurrentPlan';
import Transactions from '../Profile/Transactions';
import EditProfile from '../Profile/EditProfile';
import BottomNav from '../Nav/BottomNav';
import Account from './Account';
import Settings from '../Settings/Settings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Divider from '@mui/material/Divider';
import './Profile.css'
import {useNavigate} from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import useThemeStyle from '../../hook/useThemeStyle';

const ProfilePage=(props)=>{
	const currentUser  = localStorage.getItem('currentUser');
	const currentProfile = localStorage.getItem("profile_name");
	const currentProfileIcon = localStorage.getItem("profile_icon");
	const region = localStorage.getItem("region");
	const [isFooter, setFooter] = useState({footerActive:true, bottomNavActive:false});
	const [profileState, setProfileState] = useState({firstName:'', lastName:'', email:''})
	const {user, setUser} = useContext(AuthContext)
	const { closeModal } = useContext(ModalContext);
	const [primaryUser, setPrimaryUser] = useState()
	const [tabKey, setTabKey] = useState("current")
	const axios_ = axios.create({baseURL: process.env.REACT_APP_BASE_URL})
	const settingStyle = useThemeStyle();

	const navigate = useNavigate()

	const getUser= async ()=>{
		const resp = await axios_.get(`/user/${currentUser}`);
		const {data} =  resp.data;

		if(resp.data.success){
			console.log(resp.data)
			setPrimaryUser(data)

			try{
				if(data.fullName.length > 0 && data.fullName !== undefined){
					let fullName = data.fullName.split(" ")
					let firstName = fullName[0].trim();
					let lastName = fullName[1].trim();
           
					setProfileState({...profileState, firstName:firstName, lastName:lastName, email: data.email})
                
				}else{
					setProfileState({...profileState, firstName:'firstName', lastName:'lastName', email: data.email})
				}
			}catch(e){
				console.log(e)
			}
		}else{
			console.log(resp.data.message)
		}
	}

	function handleSelect(tabKey){
		setTabKey(tabKey)
	}


	const onBackPressed = () => {
		navigate(-1);
	}
	useEffect(()=>{
		getUser();
		//Determine Mobile or not
		if($(window).width() <= 600){
			setFooter({...isFooter, footerActive:false, bottomNavActive:true})
		}else{
			setFooter({...isFooter, footerActive:true, bottomNavActive:false})
		}
	},[])

	return(
		<div className="bg-Profile" onClick={() => closeModal()}>
			<NavBarSearch region={region} profile={currentProfile}/>
			
			<div className="container">
				
				<div className="d-flex mb-3 mt-1">
					<div className="mr-2">
						<ArrowBackIcon fontSize={'small'} className="bg-none" sx={{cursor: 'pointer', color:'white'}} style={settingStyle} onClick={onBackPressed}/>		
					</div>
					<div>
						<h4 className="bg-none" style={settingStyle}>Profile &#38; Settings</h4>
						<div className="d-flex">
							<Divider  textAlign="left" sx={{border: "2px solid #C92941", background: '#C92941', borderRadius: '8px', width: '40px', marginRight: '5px', opacity: 1,}}></Divider>
							<Divider  textAlign="left" sx={{border: "2px solid #C92941", background: '#C92941', borderRadius: '8px', width: '5px', opacity: 1,}}></Divider>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-md-6">
						{/* Use profile pic */}
						<div className="d-flex mt-md-5">
							<img className="profile-picture mr-md-3" src={currentProfileIcon} alt=""/>
							<h5 style={{fontWeight:'700', lineHeight:'30px'}} className="text-white mt-md-3">{profileState.firstName+" "+profileState.lastName}</h5>
							<span className="profile-email ml-4"><p className='account-email'>{profileState.email}</p></span>
							{/* <p className="text-bold text-white profile" data-name={profileState.firstName}>Welcome </p> */}
						</div>
					</div>

					{/* {primaryUser ?
						(primaryUser.profiles[0].profile_name === currentProfile ?
							<div className="col-md-6">
								<span className="d-flex">
									<button className="edit-prof-btn py-1 px-5" onClick={() => handleSelect("profile")}>Edit Profile</button>
									<Link className="upgrade-plan-btn py-1 px-5" to="/login">Upgrade Plan</Link>
								</span>
							</div> : null
						) 
						: null
					} */}
				</div>

				<div className="row mt-5">
					<div className="col-12 col-md-12">
						<div className="card profile-card px-md-3">
							<div className="card-body">
								<Tabs className='justify-content-around' defaultActiveKey={tabKey} id="uncontrolled-tab-example" onSelect={handleSelect} >
									<Tab eventKey="profile" title="Profiles">
										<EditProfile settingStyle={settingStyle}/>
									</Tab>
									<Tab eventKey="current" title="Subscription">
										<CurrentPlan />
										{/* <h2 className="ml-md-2 py-3 text-view-transactions">View Transactions</h2>
										<Transactions className="mt-md-3"/> */}
									</Tab>
									<Tab eventKey="account" title="Account">
										<Account firstName={profileState.firstName} lastName={profileState.lastName} email={profileState.email}/>
									</Tab>
									<Tab eventKey="settings" title="Settings">
										<Settings/>
									</Tab>
								</Tabs>
							</div>
						</div>
					</div>
				</div>
			</div>
			{isFooter.footerActive ? <Footer/> : <BottomNav className="d-block d-md-none d-sm-block"/>}
		</div>
	

		
	)

}

export default ProfilePage;