import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

const ThemeContextProvider = (props) => {
	const [darkMode, setDarkMode] = useState(false);

	const darkTheme = {
		background: '#2D132C',
		text: 'white'
	};

	const lightTheme = {
		background: 'rgb(50, 29, 49)',
		text: '#C92941'
	};

	// Initialize theme from localStorage if available
	useEffect(() => {
		const savedTheme = localStorage.getItem('darkMode');
		if (savedTheme !== null) {
			setDarkMode(JSON.parse(savedTheme));
		}
	}, []);

	const toggleTheme = () => {
		setDarkMode((prevMode) => {
			const newMode = !prevMode;
			localStorage.setItem('darkMode', JSON.stringify(newMode)); // Save theme selection
			return newMode;
		});
	};

	return (
		<div>
			<ThemeContext.Provider value={{ darkMode, toggleTheme, darkTheme, lightTheme }}>
				{props.children}
			</ThemeContext.Provider>
		</div>
	);
};

export default ThemeContextProvider;
