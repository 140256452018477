import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MovieCard from '../MoviesPage/MovieCardComponent';
import { CustomPrevArrow } from "../Svg/PrevArrow";
import { CustomNextArrow } from "../Svg/NextArrow";

const ContentSlider = (props) => {
	const [recentlyAdded, setRecentlyAdded] = useState([]);

	useEffect(() => {
		if (props.recentlyAdded && props.recentlyAdded.length > 0) {
			setRecentlyAdded(props.recentlyAdded);
		}
	}, [props.recentlyAdded]);

	useEffect(() => {
		console.log('recently added state updated:', recentlyAdded);
	}, [recentlyAdded]);

	const settings = {
		dots: true,
		speed: 500,
		infinite: true,
		slidesToShow: 5,
		slidesToScroll: 1,
		swipeToSlide: true,
		autoplaySpeed: 2000,
		prevArrow: <CustomPrevArrow />,
		nextArrow: <CustomNextArrow />,

		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 5,
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 4,
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 3,
				}
			},
		]
	};

	const contentNameStyle = {
		bottom:'40px', 
		right: window.innerWidth < 600 ? '0px' : '10px',
		position:'relative', 
		fontSize: window.innerWidth < 600 ? '16px' : '18px'
	}

	return (
		<div className="content">
			<div className="custom-container">				
				{
					recentlyAdded.length > 1 ?
						<Slider {...settings}>
							{recentlyAdded.map((content, pos) => (
								<div key={pos}>
									<div className="movie-show">
										<MovieCard 
											image={content.video_image_url !== undefined ? content.video_image_url : 'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1697176380/default_content_image_waw6bf.png'} 
											content_id={content.content_id}
											actors={content.actors}
											content_name={content.content_name}
											date_uploaded={content.date_uploaded}
											genre={content.genre}
											video_path={content.video_path}
											length={content.length}
											description={content.content_description ? content.content_description: "" }
											header_image={content.video_header_image_url !== undefined ? content.video_header_image_url : 'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1697176380/default_content_image_waw6bf.png'}
										/>
										{/* Content name-- once > 14 chars truncate */}
										<span className='' style={contentNameStyle}>
											{
												window.innerWidth < 600 ?
													(content.content_name.length < 15 ? content.content_name : content.content_name.substring(0,14)) :
													content.content_name
											}
										</span>  
									</div>
								</div>
							))}
						</Slider>
						: recentlyAdded.length === 1 ? 
							<div className="movie-show">
								<MovieCard key={recentlyAdded[0]} 
									image={recentlyAdded[0].video_image_url !== undefined ? recentlyAdded[0].video_image_url : 'https://d2fft6uz2yutyc.cloudfront.net/default_video_image.png'} 
									content_id={recentlyAdded[0].content_id}
									actors={recentlyAdded[0].actors}
									content_name={recentlyAdded[0].content_name}
									date_uploaded={recentlyAdded[0].date_uploaded}
									genre={recentlyAdded[0].genre}
									video_path={recentlyAdded[0].video_path}
									length={recentlyAdded[0].length}
									description={recentlyAdded[0].content_description ? recentlyAdded[0].content_description: "" }
									header_image={recentlyAdded[0].video_header_image_url ? recentlyAdded[0].video_header_image_url : 'https://res.cloudinary.com/www-softroniiks-com/image/upload/v1708159982/default_content_header_uvxcnu.png'}
								/>

								{/* Content name-- once > 14 chars truncate */}
								<span className='' style={contentNameStyle}>
									{
										window.innerWidth < 600 ?
											(recentlyAdded[0].content_name.length < 15 ? recentlyAdded[0].content_name : recentlyAdded[0].content_name.substring(0,14)) :
											recentlyAdded[0].content_name
									}
								</span>  
							</div> 
							: <p className='mb-md-4 mt-md-2' style={{marginLeft:'32px'}}>Loading...</p>
				}
			</div>
		</div>
	);
};

export default ContentSlider;
