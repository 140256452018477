import {useState, useEffect, useContext} from 'react';
import { ModalContext } from '../../context/ModalContext';
import { useNavigate } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Divider from '@mui/material/Divider';
import BottomNav from '../Nav/BottomNav';
import './Shorts.css'
import NavBar from '../Nav/TopNavBarV2';
import ShortIndex from './index';
import useThemeStyle from '../../hook/useThemeStyle';

const AfClips = () => {
	const navigate = useNavigate()
	const profileName = localStorage.getItem("profile_name");
	const profileIcon = localStorage.getItem("profile_icon");
	const { closeModal } = useContext(ModalContext);
	const region = localStorage.getItem("region");
	const [isFooter, setFooter] = useState({footerActive:true, bottomNavActive:false});
	const settingStyle = useThemeStyle();

	useEffect(()=>{
		//Determine Mobile or not
		if($(window).width() <= 600){
			setFooter({...isFooter, footerActive:false, bottomNavActive:true})
		}else{
			setFooter({...isFooter, footerActive:true, bottomNavActive:false})
		}
	},[])

	const handleGoBack = () => {
		navigate(-1);
	}

	return (
		<>
			<div className="bg-shortsPage" onClick={() => closeModal()}>
				<NavBar profile={profileName} profileIcon={profileIcon} region={region}/>
				<div className="container">
					<div className="d-flex mb-5 mt-5">
						<div className="mr-2" onClick={handleGoBack}>
							<ArrowBackIcon fontSize={'small'} style={settingStyle} className="bg-none" sx={{cursor: 'pointer', color: '#fff'}} onClick={handleGoBack}/>		
						</div>
						<div style={{cursor: 'pointer'}} onClick={handleGoBack}>
							<h4 className="bg-none" style={settingStyle}>AfClips</h4>
							<div className="d-flex">
								<Divider  textAlign="left" sx={{border: "2px solid #C92941", background: '#C92941', borderRadius: '8px', width: '40px', marginRight: '5px', opacity: 1,}}></Divider>
								<Divider  textAlign="left" sx={{border: "2px solid #C92941", background: '#C92941', borderRadius: '8px', width: '5px', opacity: 1,}}></Divider>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col-md-12'>
							<ShortIndex/>
						</div>
					</div>
				</div>
			</div>
			{isFooter.footerActive ? '' : <BottomNav className="d-block d-md-none d-sm-block"/>}
		</>
	);
};

export default AfClips
