import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Typography } from "@mui/material";
import { axiosInstance } from "../../apiClient";
import CustomAccordionItem from './Customaccordion';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import Button from '@mui/material/Button';
import './ExplorePlan.css';


const ExplorePlans = ({ current, userId, toggleExplore, currentPlan }) => {
	const [plans, setPlan] = useState([]);
	const navigate = useNavigate();
	const [transactions, setTransactions] = useState([]);
	//const [subsriptions, setSubscriptions] = useState([]);
	
	const indexOfCurrentPlan = plans.findIndex(plan => plan.subscription_tier === currentPlan);
	const validIndexOfCurrentPlan = indexOfCurrentPlan >= 0 && indexOfCurrentPlan < 3 ? indexOfCurrentPlan : 0;
	
	const [expanded, setExpanded] = React.useState(`panel${validIndexOfCurrentPlan}`);

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};


	const getPlans = async () => {
		const res = await axiosInstance.get("/plans");
		if (res.status === 200) {
			setPlan(res.data.plans);

			for (const plan of res.data.plans) {
				if (plan.stripe_price_id === current.priceId) {
					defaultRadioPlan = plan.subscription_name;
				}
			}
		}
	};


	const getTransactionHistory = async () => {
		const res = await axiosInstance.get(`/${userId}/stripe/transactions`);
		const { data } = res.data;
		if (res.status === 200 && data !==null) {
			setTransactions(res.data.transactions);
		}else {
			setTransactions([]);
		}
	};

	// const getSubscHistory = async () => {
	// 	const res = await axiosInstance.get(`/${userId}/stripe/subscriptions`);
	// 	const { data } = res.data;
	// 	console.log({data});
	// 	if (res.status === 200 && data !==null) {
	// 		setSubscriptions(res.data.subscriptions);
	// 	}else {
	// 		setSubscriptions([]);
	// 	}
	// };

	useEffect(() => {
		getPlans();
		getTransactionHistory();
		//getSubscHistory();
	}, []);
	
	const handleGoBack = () => {
		toggleExplore();
	}

	const formatSubName = (subName) => { 
		if(subName.includes(':')){
			// Nigeria
			return subName.split(':')[1].split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') 
		}else{
			return subName.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') 
		}
	}

	const getPlanTierInfo = (plan) => {
		if(plan === 'basic-plan'){
			return 0
		}else if(plan === 'mid-plan'){
			return 1
		}else{
			return 2
		}
	}

	const getUserType = (plan) => {
		if(plan === 'basic-plan'){
			return 'For individuals'
		} else if (plan === 'mid-plan'){
			return 'For pair'
		} else {
			return 'For family'
		}
	}

	const getPlanType = (plan) => {
		if(plan === 'basic-plan'){
			return 'basic'
		} else if (plan === 'mid-plan'){
			return 'mid'
		} else {
			return 'premium'
		}
	}

	const purchaseNow = (plan) => {
		navigate(`/pricing/start-your-plan/${getPlanType(plan.subscription_name)}`, 
			{ state: 
				{ 
					planName: plan.subscription_tier, 
					price: plan.price, nollyWood: plan.region_allowed.includes("W"), 
					gollyWood: plan.region_allowed.includes("W"), 
					highDefinition: plan.high_definition, 
					screens: plan.screens, 
					devices: plan.screens, 
					ultraHD: plan.ultraHighDefinition, 
					priceId: plan.stripe_price_id, 
					productId: plan.stripe_product_id 
				} 
			});
	}
	console.log({plans})

	return (
		<div>
			<div className="mr-2 mt-4">
				<ArrowBackIcon fontSize={'small'}  sx={{cursor: 'pointer', color: 'white'}} onClick={handleGoBack}/>		
			</div>

			<div className="row">
				<div className="col-md-6 mt-5">
					{
						plans.length > 0 && plans.map((plan, index) => {
							return ( 
								<CustomAccordionItem
									key={index}
									expanded={expanded}
									onChange={handleChange}
									panel={plan.subscription_tier === currentPlan ? `panel${validIndexOfCurrentPlan}` : `panel${index}`}
									plan={formatSubName(plan.subscription_name)}
									user={getUserType(plan.subscription_name)}
									current={plan.subscription_tier === currentPlan && 'Current plan'}
									planCurrent={plan.subscription_tier === currentPlan ? true : false}
									price={plan.price}
									details={
										<div style={{color: 'white'}}>
											<List>
												<ListItem disablePadding>
													<ListItemButton>
														<ListItemIcon>
															<DoneIcon sx={{color:'#06BA83 !important', fill: '#06BA83'}}/>
														</ListItemIcon>
														<ListItemText primary="Nigeria, Ghana, South Africa" />
													</ListItemButton>
												</ListItem>
												<ListItem disablePadding>
													<ListItemButton>
														<ListItemIcon>
															<DoneIcon sx={{color:'#06BA83 !important', fill: '#06BA83'}}/>
														</ListItemIcon>
														<ListItemText primary={plan.subscription_name === 'basic-plan' ? 'Unlimited' : `${plan.screens} Devices`} />
													</ListItemButton>
												</ListItem>
												<ListItem disablePadding>
													<ListItemButton>
														<ListItemIcon>
															<DoneIcon sx={{color:'#06BA83 !important', fill: '#06BA83'}}/>
														</ListItemIcon>
														<ListItemText primary={plan.subscription_name === 'basic-plan' ? 'Unlimited' : `${plan.screens} Screens`} />
													</ListItemButton>
												</ListItem>
												<ListItem disablePadding>
													<ListItemButton>
														<ListItemIcon>
															<DoneIcon sx={{color:'#06BA83 !important', fill: '#06BA83'}}/>
														</ListItemIcon>
														<ListItemText primary="Unlimited Movies and TV shows" />
													</ListItemButton>
												</ListItem>
												<ListItem disablePadding>
													<ListItemButton>
														<ListItemIcon>
															<DoneIcon sx={{color:'#06BA83 !important', fill: '#06BA83'}}/>
														</ListItemIcon>
														<ListItemText primary="Watch on you laptop, phone, or tablet" />
													</ListItemButton>
												</ListItem>
												<ListItem disablePadding>
													<ListItemButton>
														<ListItemIcon>
															<DoneIcon sx={{color:'#06BA83 !important', fill: '#06BA83'}}/>
														</ListItemIcon>
														<ListItemText primary={'HD available'} />
													</ListItemButton>
												</ListItem>
												<ListItem disablePadding>
													<ListItemButton>
														<ListItemIcon>
															{
																getPlanTierInfo(plan.subscription_name) === 0 || getPlanTierInfo(plan.subscription_name) === 1 ? (
																	<ClearIcon sx={{color:'#C92941'}}/>
																) :
																	(
																		<DoneIcon sx={{color:'#06BA83 !important', fill: '#06BA83'}}/>
																	)
															}
														</ListItemIcon>
														<ListItemText primary="Ultra HD available" />
													</ListItemButton>
												</ListItem>
											</List>
											{
												plan.subscription_name === 'basic-plan' ? 
													(

													
														plan.subscription_tier === currentPlan ?
															<Button variant="contained" className="py-3 my-3 purchase-btn" style={{fontWeight:'bold'}} onClick={() => purchaseNow(plan)} disabled>Current Plan</Button>
															:
															<Button variant="contained" className="py-3 my-3 purchase-btn"  onClick={() => purchaseNow(plan)}>Purchase Now</Button>	
														
													
													)
													:
													(
														<Button variant="contained" className="py-3 my-3 purchase-btn" style={{fontWeight:'bold'}} onClick={() => purchaseNow(plan)} disabled>Coming Soon</Button>	
													)
											}
										</div>
									}
									number={1} // Or any number you want to display
								/>
							) 
						}) 
					}
				</div>

				<div className="col-md-1 d-none d-md-block">
					<div
						className="mt-5"
						style={{
							backgroundColor: "#512350",
							width: "1px",
							height: "400px",
						}}
					></div>
				</div>

				{/* Transaction History */}
				<div className="col-md-5 text-white">
					<p className="mt-5">Transaction History</p>
					<div style={{ height: '300px' }}>
						{/* {transactions.length > 0 ? transactions.map((t) => {
							return (
								<div key={t.description}>
									<p>{t.description}</p>
									<span className='price-transaction-position'>
										<p>${t.amount / 100}</p>
										<p>{new Date(t.created * 1000).toDateString()}</p>
										<span>{(t.status == 'requires_payment_method' ? 'No payment' : t.status)}</span>
									</span>
									<hr style={{margin:'10px', width:'100%'}} />
								</div>
							);
						}) : 
							<div>
								<h6>No Transaction Histiory Found!</h6>
							</div>} */}

						{
							transactions.length > 0 ? 
								(
									<div className="transaction-section">
										{

											transactions.map((transaction, index) => {
												return (
													<div key={index} style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px", paddingRight:'20px', paddingBottom:'5px'}}>
														<div>
															<Typography
																style={{
																	color: "#ffffff",
																	marginBottom: "5px",
																	fontSize: "16px",
																	fontWeight: 600,
																	fontFamily: `'Poppins', sans-serif`,
																}}
															>
																{transaction.description}
															</Typography>
														</div>
														
														<div>
															<Typography
																style={{
																	color: "#ffffff",
																	marginBottom: "5px",
																	fontSize: "16px",
																	fontWeight: 600,
																	fontFamily: `'Poppins', sans-serif`,
																}}
															>
																${transaction.amount / 100}
															</Typography>
															<Typography
																style={{
																	color: "#98A2B3",
																	marginBottom: "5px",
																	fontSize: "14px",
																	fontWeight: 400,
																	fontFamily: `'Poppins', sans-serif`,
																}}
															>{new Date(transaction.created * 1000).toDateString()}
															</Typography>
															<span>{(transaction.status == 'requires_payment_method' ? 'No payment' : transaction.status)}</span>
															<hr />
														</div>
														
													</div>
												)
											})
										}
									</div>
								) : 
								(
									<div>
										<h6>No Transaction History Found!</h6>
									</div>

								)
						}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ExplorePlans;