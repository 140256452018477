import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
	BottomNavigation,
	BottomNavigationAction,
	IconButton,
	Drawer,
	List,
	ListItem,
	ListItemText,
	FormControl,
	Select,
	MenuItem,
	SelectProps,
	SelectChangeEvent,
} from "@mui/material";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import AddIcon from "@mui/icons-material/Add";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import SystemUpdateAltRoundedIcon from "@mui/icons-material/SystemUpdateAltRounded";
import SlowMotionVideoOutlinedIcon from "@mui/icons-material/SlowMotionVideoOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./BottomNav.css";


const CustomDropdownIcon = () => (
	<ArrowDropDownIcon style={{ color: "white" }} />
);

const BottomNav = () => {
	const [navValue, setValue] = useState(0);
	const [drawerOpen, setDrawerOpen] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const {
		t,
		i18n: { changeLanguage, language },
	} = useTranslation();
	const currentUser = localStorage.getItem("currentUser");

	const toggleDrawer = (open) => (e) => {
		if (e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) {
			return;
		}
		setDrawerOpen(open);
	};

	const handleDownloadPolicy = (e) => {
		e.preventDefault();
		const fileUrl = "https://res.cloudinary.com/www-softroniiks-com/image/upload/v1690406407/AfroWatch/files/policy_iv2scz.pdf";
		const fileName = "Afrowatch Privacy Policy.pdf";

		const link = document.createElement("a");
		link.href = fileUrl;
		link.download = fileName;
		link.target = "_blank";

		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const handleDownloadTerms = (e) => {
		e.preventDefault();
		const fileUrl = "https://res.cloudinary.com/www-softroniiks-com/image/upload/v1690489707/AfroWatch/files/terms-of-use_gihwge.pdf";
		const fileName = "Afrowatch Terms of Use.pdf";

		const link = document.createElement("a");
		link.href = fileUrl;
		link.download = fileName;
		link.target = "_blank";

		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<div>
			<BottomNavigation showLabels value={navValue} onChange={(event, newValue) => {() => setValue(newValue)}} className="bottom-nav" style={{bottom:location.pathname == '/my-list' || location.pathname == '/downloads' ? '0px':'', color: 'white'}} >
				{/* <img src={icons.iconHome} alt="icon_home" className="bottom-nav-icon-img"/> */}
				<BottomNavigationAction
					label="Menu"
					icon={<MenuIcon style={{ color: "white" }} />}
					onClick={toggleDrawer(true)}
				/>
				<BottomNavigationAction label="New" icon={<SystemUpdateAltRoundedIcon  style={{color: 'white'}}/>} onClick={() => navigate(`/new-arrivals`)}/>
				{/* <img src={icons.iconArrived} alt="icon_arrived" className="bottom-nav-icon-img-arrived"/> */}
				<BottomNavigationAction label="My List" icon={<AddIcon  style={{color: 'white'}}/>} className="bottom-nav-icon-img" onClick={() => navigate(`/my-list`)}/>
				<BottomNavigationAction label="Downloads" icon={<DownloadRoundedIcon  style={{color: 'white'}}/>} onClick={() => navigate(`/downloads`)}/>
				<BottomNavigationAction label="AfClips" icon={<SlowMotionVideoOutlinedIcon  style={{color: 'white'}}/>} onClick={() => navigate(`/afclips`)}/>
			</BottomNavigation>

			<Drawer
				anchor="left"
				open={drawerOpen}
				onClose={toggleDrawer(false)}
				PaperProps={{
					style: {
						backgroundColor: "#1f0f25",
						color: "white",
						width: "75%",
						maxHeight: "90vh",
						overflowY: "auto",
					},
				}}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						padding: "10px",
						marginTop: "8px",
					}}
				>
					<div
						style={{ display: "flex", alignItems: "center" }}
						onClick={() => navigate("/")}
					>
						<HomeRoundedIcon
							onClick={() =>
								navigate(`/profile/${currentUser}/watch`)
							}
							style={{ color: "white", marginRight: "10px" }}
						/>
						<ListItemText
							primary="Home"
							style={{ marginTop: "8px" }}
						/>
					</div>
					<IconButton
						edge="end"
						color="inherit"
						onClick={toggleDrawer(false)}
					>
						<CloseIcon style={{ color: "white" }} />
					</IconButton>
				</div>

				<List>
					<ListItem>
						<ListItemText primary={t("navigation")} />
					</ListItem>
					<ListItem  onClick={() => navigate("/pricing-plan")}>
						<ListItemText primary={t("pricingPlans")} />
					</ListItem>
					<ListItem  onClick={handleDownloadPolicy}>
						<ListItemText primary={t("privacyPolicy")} />
					</ListItem>
					<ListItem  onClick={handleDownloadTerms}>
						<ListItemText primary={t("termsOfService")} />
					</ListItem>

					<ListItem>
						<ListItemText primary={t("followUs")} />
					</ListItem>
					<ListItem>
						<a href="https://www.instagram.com/afrowatch.co/" target="_blank">
							<img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620740889/AfroWatch/Instagram_dhwuas.svg" alt="instagram-logo"/>
						</a>
					</ListItem>
					<ListItem>
						<a href="https://twitter.com/Afrowatch" target="_blank" >
							<img src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1620740893/AfroWatch/Twitter_da6mxz.svg" alt="twitter-logo"/>
						</a>
					</ListItem>
					<ListItem>
						<FormControl fullWidth>
							<Select
								value={language}
								aria-label="Language Selector"
								onChange={(e) => changeLanguage(e.target.value)}
								displayEmpty
								style={{ color: "white", borderColor: "white" }}
								variant="outlined"
								IconComponent={CustomDropdownIcon}
								renderValue={(selected) => (
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<img
											src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1706407601/globe_1_xqvxzl.svg"
											alt="globe"
											style={{ width: "20px", height: "20px", marginRight: "10px" }}
										/>
										{selected}
									</div>
								)}
							>
								<MenuItem value="en">English</MenuItem>
								<MenuItem value="xh">Xhosa</MenuItem>
								<MenuItem value="np">Nigerian Pidgin</MenuItem>
								<MenuItem value="yb">Yoruba</MenuItem>
								<MenuItem value="fr">French</MenuItem>
								<MenuItem value="igb">Igbo</MenuItem>
								<MenuItem value="sml">Somali</MenuItem>
								<MenuItem value="twi">Twi</MenuItem>
							</Select>
						</FormControl>
					</ListItem>

					<hr className="my-4" />
					<p className="copy-right">
						&#169; 2024 Afrowatch, inc. All Rights Reserved.
					</p>
				</List>
			</Drawer>
		</div>
	);
};

export default BottomNav;