import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { axiosInstance } from '../../apiClient';
import './NavBarSearch.css';
import SearchResult from './SearchResultDisplay';

const Search = (props) => {
	const [searchParams, setSearchParams] = useSearchParams('');
	const [searchResult, setSearchResult] = useState();
	const [searchVal, setSearchVal] = useState('');
	const [isSearching, setIsSearching] = useState(false);

	const searchTerm = searchParams.get('q') || '';

	const CloseSearchbar = () => {
		setSearchParams({ q: '' });
		setSearchVal('');
		setSearchResult(null); // Clear search result
		props.onClose();
	};

	// Debounce function to limit API calls while typing
	const debounce = (func, delay) => {
		let timer;
		return (...args) => {
			clearTimeout(timer);
			timer = setTimeout(() => func(...args), delay);
		};
	};

	const performSearch = async (value) => {
		if (value) {
			setIsSearching(true);
			setSearchParams({ q: value });

			try {
				const res = await axiosInstance.get(`/search/${localStorage.getItem('region')}?q=${value}`);
				if (res.data.status === 200) {
					setSearchResult(res.data.content);
				}
			} catch (error) {
				console.error('Error searching:', error);
			} finally {
				setIsSearching(false);
			}
		} else {
			setSearchParams({});
			setSearchResult(null); // Clear search result if input is cleared
		}
	};

	const debouncedSearch = debounce(performSearch, 500); // Adjust the delay as needed

	const handleSearchChange = (e) => {
		const value = e.target.value;
		setSearchVal(value);
		debouncedSearch(value);
	};

	const onFormSubmit = (e) => {
		e.preventDefault(); // Prevent form submission and page reload
		performSearch(searchVal);
	};

	const styleOne = {
		position: 'relative',
		bottom: '12px',
	};

	const searchStyle = !window.location.pathname.includes('pricing-plan') ? styleOne : {};

	return (
		<>
			<div style={searchStyle}>
				<form onSubmit={onFormSubmit} action="" method="post">
					<input
						className="search border"
						type="search"
						name="search"
						placeholder="Search content"
						value={searchVal}
						onChange={handleSearchChange}
						style={{ color: 'white' }}
					/>
					{!window.location.pathname.includes('pricing-plan') ? (
						<button
							type="button"
							onClick={CloseSearchbar}
							style={{ background: 'none', border: '0px', color: 'white' }}
						>
							<CloseRoundedIcon style={{ color: 'white' }} />
						</button>
					) : null}
				</form>
			</div>

			{searchTerm && (
				<div className="search-result">
					{isSearching ? (
						<p className="text-white">Loading...</p> // Display a loading message or spinner while searching
					) : (
						searchResult &&
						searchResult[searchTerm] &&
						searchResult[searchTerm].map((item) => (
							<SearchResult
								key={item.contentId}
								contentId={item.contentId}
								contentName={item.contentName}
								description={item.description}
								actors={item.actors}
								header_image={item.header_image}
								length={item.length}
							/>
						))
					)}
				</div>
			)}
		</>
	);
};

export default Search;
